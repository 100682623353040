import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import AnchorLink from "react-anchor-link-smooth-scroll"
import meeting from "../images/about_meeting.jpg"
import businessImg from "../images/icons/iconmonstr-business.png"
import technologyImg from "../images/icons/iconmonstr-technology.png" 
import digitalImg from "../images/icons/iconmonstr-digital.png"
class Homepage extends React.Component{
  render(){
    return(

  <Layout pageType="homepage">
    <SEO title="Home" />

<section id="_intro" className="hero is-fullheight intro">
  <div className="overlay-image"></div>
  <div className="overlay"></div>
  <div className="hero-body">
    <div className="container">
      <h1 className="title">
        <span>Digital, Technology, and Business consulting </span><br />
        <span>to optimize and improve your organization</span>
      </h1>
      <div className="button-area">
        <Link to="/contact-us" className="button gold">Get started</Link>
      </div>
    </div>
  </div>

  <div className="hero-foot">
    <div className="container">
      <div className="container demo">
        <a href="#about_scroll"><span></span><span></span><span></span></a>
      </div>
    </div>
  </div>
</section>
        
        <section id="about" className="section about">
          <div className="container" id="about_scroll">
            <div className="columns">

              <div className="column about_left">
                <div className="inner">
                  <h1 className="title">Let's optimize your business, <br />
                    discover your potential.
                  </h1>
                  <div className="image">
                    <img src={meeting} />
                  </div>
                </div>

              </div>

              <div className="column about_right">
                <div className="inner">
                  <h3 className="big-text">Guaranteeing you a competitive advantage.</h3>
                  <br />
                  <p>
                    We can give you the maximum value for the lowest price possible. Reliable and professional advice,
                    our consultants firmly believe that consulting is a group activity. Which is why we work with you,
                    not just for you, to create new solutions that will allow your organization to create new ideas and
                    discover new innovations. Allowing your organization to be far better organized, as well as
                    resulting in a significant boost in your technology capabilities across all fields.
                  </p>
                  <Link to="/about-us" className="button gold">Learn more</Link>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section id="solutions" className="section solutions">
          <div className="container">

            <div className="columns">
              <div className="column"><div className="inner business-consulting">
                <p className="title">Business Consulting </p>
                <span className="icon">
                  <img src={businessImg} alt=""/>
                </span>
                <p className="subtitle">Let us optimize and transform your business, from your people to your processes.</p>
                </div></div>
              <div className="column"><div className="inner technology-solutions">
                <p className="title">Technology Solutions</p>
                <span className="icon">
                <img src={technologyImg} alt=""/>
                </span>
                <p className="subtitle">Outline, create, and deliver efficient technology solutions that are guaranteed to succeed.</p>
                </div></div>
              <div className="column"><div className="inner digital-solutions">
                <p className="title">Digital Solutions</p>
                <span className="icon">
                <img src={digitalImg} alt=""/>
                </span>
                <p className="subtitle">Overhaul your marketing plan and make your digital strategy succeed.</p>
                </div></div>
            </div>

          </div>


        </section>

    

        <section id="_insights" className="section insights blog-list blue">
        <div className="container">
          <div className="title-area">
            <h1 className="title">Our latest insights</h1>
          </div>
          <div className="columns is-multiline">
            <div className="single column is-4">
                <Link to="/blog/choosing-the-right-business-process-management-solution/" className="inner">
                  <p className="category"><span className="line"></span>BUSINESS</p>
                  <h2 className="title">Choosing the right Business Process Management Solution (BPMS)</h2>
              </Link>
            </div>

            <div className="single column is-4">
                <Link to="/blog/improving-customer-experience-with-an-advisory-board/" className="inner">
                  <p className="category"><span className="line"></span>TECHNOLOGY</p>
                  <h2 className="title">Improving Customer Experience with an Advisory Board</h2>
                </Link>
            </div>

            <div className="single column is-4">
                <Link to="/blog/wasteful-processes-slow-down-software-delivery-efficiency-and-effectiveness/" className="inner">
                  <p className="category"><span className="line"></span>TECHNOLOGY</p>
                  <h2 className="title">Wasteful Processes Slow down Software Delivery Efficiency and Effectiveness</h2>
                </Link>
            </div>

          </div>
        </div>
      </section>
   

  </Layout>
)
 }
}

export default Homepage
